import axios from 'axios';
import { Checklist, CreateChecklistInput } from '../../../@types/checklist';
import api, { baseURL } from '../../../services/api/api';

export const getChecklists = async (query = '') => {
  const { data } = await api.get<Checklist>(
    `/checklist_vehicle?query=${query}`,
  );
  return data;
};

export const getChecklistById = async (id: string) => {
  const { data } = await axios.get<Checklist>(
    `${baseURL}checklist_vehicle/${id}`,
  );
  return data;
};

export const getChecklistDraftById = async (id: string) => {
  const { data } = await api.get<Checklist>(`/checklist_vehicle/draft/${id}`);
  return data;
};

export const createChecklist = async (props: CreateChecklistInput) => {
  const { data } = await api.post<Checklist>('/checklist_vehicle/v2', {
    ...props,
  });
  return data;
};

export const transformDraftChecklist = async (id: string) => {
  const { data } = await api.post<Checklist>(
    `/checklist_vehicle/transform/${id}`,
  );
  return data;
};

export const editChecklist = async (props: CreateChecklistInput) => {
  const { data } = await api.put<Checklist>('/checklist_vehicle/v2', {
    ...props,
  });
  return data;
};

export const deleteChecklist = async (id: string) => {
  const { data } = await api.delete(`/checklist_vehicle/${id}`);
  return data;
};
