import { Route, Routes as Switch } from 'react-router-dom';

import { OrderServiceReport } from '../../utils/pdf/OrderService';
import { Workshop } from '../../@types/interface';
import { useAuth } from '../../hooks/useAuth';
import { ChecklistPrivateReport } from '../../pages/common/ChecklistReport/private';

interface Props {
  workshopData: Workshop | null;
}

export const ReportRoutes = ({ workshopData }: Props) => {
  const { user } = useAuth();
  const userPermissions = user?.permissions ?? [];

  const hasOrderServicePermission = userPermissions.some(
    permission => permission.code === 'OS',
  );

  if (hasOrderServicePermission) {
    return (
      <Switch>
        <Route
          path="/order-service-report"
          element={<OrderServiceReport workshopData={workshopData} />}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path="/order-service-report"
        element={<OrderServiceReport workshopData={workshopData} />}
      />
      <Route
        path="/transaction-report"
        element={<OrderServiceReport workshopData={workshopData} />}
      />
      <Route path="/checklist-draft" element={<ChecklistPrivateReport />} />
    </Switch>
  );
};
