import { ComponentProps } from 'react';
import styles from './table-root.module.css';
import responsive from './table-root-responsive.module.css';

type TableRootProps = ComponentProps<'table'> & {
  AutoResponsive?: boolean;
  height?: string;
  tableScrollClassName?: string;
};

export const TableRoot = ({
  children,
  AutoResponsive = false,
  height = '100%',
  tableScrollClassName,
  ...props
}: TableRootProps) => {
  return (
    <div
      className={`${styles.scroll} ${tableScrollClassName}`}
      style={{ height }}
    >
      <table
        {...props}
        className={`${styles['table-container']} ${props.className} ${
          AutoResponsive && responsive.auto
        }`}
      >
        {children}
      </table>
    </div>
  );
};
