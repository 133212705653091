import { TextareaHTMLAttributes, ReactNode } from 'react';
import styles from './textarea-component.module.css';
import { LoadingSpinner } from '../LoadingSpinner';

type TextareaStates = 'default' | 'error' | 'success' | 'selected' | 'disabled';

interface TextareaComponentProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  placeholder?: string;
  hint?: string;
  icon?: ReactNode;
  hasError?: boolean;
  className?: string;
  width?: string;
  isLoading?: boolean;
  state?: TextareaStates;
}

export const TextareaComponent = ({
  hint,
  label,
  placeholder,
  icon,
  className,
  width = '',
  hasError = false,
  isLoading = false,
  state = 'default',
  ...props
}: TextareaComponentProps) => {
  return (
    <div
      className={`${styles['textarea-container']} ${
        hasError ? styles['has-error'] : ''
      } ${className} ${styles[state]}`}
      style={{ width }}
    >
      {label && (
        <label htmlFor="textarea" className={styles['textarea-label']}>
          {label}
          {isLoading && <LoadingSpinner size={16} />}
        </label>
      )}
      <div className={styles['textarea-wrapper']}>
        <textarea
          id="textarea"
          placeholder={placeholder}
          className={styles.textarea}
          {...props}
        />
        {icon && <span className={styles['textarea-icon']}>{icon}</span>}
      </div>
      {hint && <p className={styles['textarea-hint']}>{hint}</p>}
    </div>
  );
};
