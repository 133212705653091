import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { CaretLeft, PaperPlaneRight, XCircle } from 'phosphor-react';
// import { useQuery } from 'react-query';
import { ExpandableSection } from '../../../shared/ExpandableSection';
import ImageInput from '../../../shared/ImageInput';
import { getLogo } from '../../../utils/getLogo';
import styles from './checklist-report.module.css';
import { TextareaComponent } from '../../../shared/TextareaComponent';
import { categories } from '../Checklists/components/CreateChecklistModal';
import { Checklist, ChecklistVehicleImg } from '../../../@types/checklist';
import { getChecklistByIdController } from '../Checklists/controller';
import { dateOffset } from '../../../utils/dateFunctions';
import { maskPhone } from '../../../services/helpers/mask';
import { FeedbackModal } from '../../../shared/FeedbackModal';
import { Button } from '../../../shared/Button';
import { isAuthenticated } from '../../../services/api/auth';
import { ErrorModal } from '../Checklists/components/ErrorModal';
import useSendChecklistModal from '../Checklists/hooks/useSendChecklistModal';
import { SendChecklistModal } from '../Checklists/components/SendChecklistModal';

export const ChecklistPublicReport = () => {
  const [checklist, setChecklist] = useState<Checklist | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const sendModal = useSendChecklistModal();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const checklistId = queryParams.get('checklistId');

  // useQuery({
  //   queryKey: ['checklist', checklistId],
  //   queryFn: async () => {
  //     const response = await getChecklistByIdController(checklistId ?? '');
  //     return response;
  //   },
  //   enabled: !!checklistId, // Só executa se o ID estiver disponível
  //   staleTime: 1000 * 60 * 5, // Define um tempo de cache de 5 minutos
  // });

  const fetchChecklist = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const res = await getChecklistByIdController(id);
      if (res) {
        setChecklist(res);
      }
    } catch (err) {
      // setError(handleErrorMessage(err));
      setError('Checklist não encontrado!');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (checklistId) {
      fetchChecklist(checklistId);
    }
  }, [checklistId, fetchChecklist]);

  const logo = useMemo(() => getLogo(), []);
  // datas
  const creationDate = useMemo(() => {
    if (!checklist?.creation_date) return 'Data não disponível';

    const date = dateOffset(new Date(checklist.creation_date));

    return format(date, 'dd/MM/yyyy - HH:mm');
  }, [checklist?.creation_date]);

  const updatedAt = useMemo(() => {
    if (!checklist?.updated_at) return 'Data não disponível';

    const date = dateOffset(new Date(checklist.updated_at));

    return format(date, 'dd/MM/yyyy - HH:mm');
  }, [checklist?.updated_at]);
  // oficina
  const fantasyName = useMemo(
    () => checklist?.workshop.fantasy_name ?? 'não encontrado',
    [checklist?.workshop.fantasy_name],
  );
  const checklistNumber = useMemo(
    () => checklist?.CHECKLIST ?? '---',
    [checklist?.CHECKLIST],
  );
  // cliente
  const clientName = useMemo(() => checklist?.name ?? '---', [checklist?.name]);
  const clientPhone = useMemo(
    () => maskPhone(checklist?.phone ?? '---'),
    [checklist?.phone],
  );
  // veículo
  const vehicleModel = useMemo(
    () => checklist?.vehicle?.model ?? '---',
    [checklist?.vehicle?.model],
  );
  const vehiclePlate = useMemo(
    () => maskPhone(checklist?.vehicle?.license_plate ?? '---'),
    [checklist?.vehicle?.license_plate],
  );
  const vehicleYear = useMemo(
    () => maskPhone(checklist?.vehicle?.year ?? '---'),
    [checklist?.vehicle?.year],
  );
  const vehicleChassi = useMemo(
    () => maskPhone(checklist?.vehicle?.chassi ?? '---'),
    [checklist?.vehicle?.chassi],
  );
  // imagens
  const groupImageByCategory = (images: ChecklistVehicleImg[]) => {
    return images.reduce((acc, img) => {
      if (!acc[img.category]) {
        acc[img.category] = [];
      }
      acc[img.category].push(img);
      return acc;
    }, {} as Record<string, ChecklistVehicleImg[]>);
  };

  const groupedImages = useMemo(() => {
    const grouped = groupImageByCategory(
      checklist?.checklist_vehicle_imgs ?? [],
    );
    return grouped;
  }, [checklist?.checklist_vehicle_imgs]);

  // descrições
  const groupDetailByCategory = useCallback(
    (category: string) => {
      const filteredDetail = checklist?.checklist_category_details?.find(
        detail => detail.category === category,
      );
      if (!filteredDetail) return '';
      return filteredDetail?.observations ?? '';
    },
    [checklist?.checklist_category_details],
  );

  // voltar a pagina
  const handleBackButtonClick = () => {
    navigate('/checklists');
  };

  const authenticated = useMemo(() => isAuthenticated(), []);

  return (
    <article className={styles['checklist-report']}>
      <FeedbackModal isOpen={isLoading} variant="sending" />
      {error && (
        <ErrorModal isOpen message={error} onClose={() => setError(null)} />
      )}
      {sendModal.isOpen && <SendChecklistModal checklist={checklist} />}
      {/* Cabeçalho do Relatório */}
      <header className={styles['report-header']}>
        <img
          src={logo}
          alt="Logo Autocenter"
          className={styles['report-logo']}
        />
        <div className={styles['report-dates']}>
          <div>
            <span>Criação</span>
            <time dateTime={creationDate}>{creationDate}</time>
          </div>
        </div>
      </header>
      {/* Conteúdo do Checklist */}
      <section className={styles['report-content']}>
        {authenticated && (
          <div className={styles['report-actions']}>
            <Button
              className={styles['back-btn']}
              handleClick={handleBackButtonClick}
              variant="register"
              icon={<CaretLeft size={24} color="var(--white)" weight="bold" />}
              // style={{
              //   minWidth: '80px',
              // }}
            >
              voltar
            </Button>

            <Button
              className={styles['send-btn']}
              handleClick={() => sendModal.onOpen()}
              variant="register"
              icon={
                <PaperPlaneRight size={24} weight="bold" color="var(--white)" />
              }
              // style={{
              //   minWidth: '80px',
              // }}
            >
              Enviar
            </Button>
          </div>
        )}
        {/* Identificação */}
        <header className={styles['report-title']}>
          <h2>{fantasyName}</h2>
          <h3>Checklist - {checklistNumber}</h3>
        </header>

        {/* Informações do Cliente */}
        <section className={styles['report-section']}>
          <h3>Cliente</h3>
          <div className={styles['content-row']}>
            <div>
              <strong>Cliente:</strong>
              <span>{clientName}</span>
            </div>
            <div>
              <strong>Telefone:</strong>
              <span>{clientPhone}</span>
            </div>
          </div>
        </section>

        {/* Informações do Veículo */}
        <section className={styles['report-section']}>
          <h3>Veículo</h3>
          <div className={styles['content-row']}>
            <div>
              <strong>Modelo:</strong>
              <span>{vehicleModel}</span>
            </div>
            <div>
              <strong>Placa:</strong>
              <span>{vehiclePlate}</span>
            </div>
            <div>
              <strong>Ano:</strong>
              <span>{vehicleYear}</span>
            </div>
          </div>
          <div className={styles['content-row']}>
            <div>
              <strong>Chassi:</strong>
              <span>{vehicleChassi}</span>
            </div>
          </div>
        </section>

        {/* Checklist */}
        <section className={styles['report-section']}>
          <h3>Checklist</h3>
          {categories.map(category => (
            <ExpandableSection
              title={category.label}
              height={200}
              key={category.name}
            >
              {(() => {
                const categoryImages = groupedImages[category.name] ?? [];

                return categoryImages.length > 0 ? (
                  <ImageInput
                    onlyView
                    size={68}
                    imagesUrls={categoryImages.map(img => img.img_url)}
                  />
                ) : (
                  <span className={styles['no-images']}>
                    <XCircle size={32} weight="bold" />
                    Sem imagens
                  </span>
                );
              })()}

              <TextareaComponent
                value={groupDetailByCategory(category.name)}
                disabled
              />
            </ExpandableSection>
          ))}
        </section>
      </section>
    </article>
  );
};
