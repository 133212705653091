import { useEffect, lazy, useCallback, useState, Suspense } from 'react';
import { ReportRoutes } from '../reports/routes';
import { CommonRoutes } from '../common/routes';
import Fallback from '../../shared/Fallback/Fallback';
import { NotificationProvider } from '../../contexts/notificationContext';
import { QuotationRoutes } from '../quotation/routes';
import { useAuth } from '../../hooks/useAuth';

const WorkshopRoutes = lazy(() => import('../workshop/routes'));
const FleetRoutes = lazy(() => import('../fleet/routes'));
const GasStationRoutes = lazy(() => import('../gasStation/routes'));

export const PrivateRoutes = () => {
  const [accessType, setAccessType] = useState<string | null>(null);
  const { workshop, user } = useAuth();

  // useEffect(() => {
  //   const shouldReload = localStorage.getItem('reload');

  //   if (shouldReload) {
  //     localStorage.removeItem('reload');
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    setAccessType(user?.role_name ?? '');
  }, [user?.role_name]);

  const handleAccessType = useCallback(() => {
    return (
      <>
        <ReportRoutes workshopData={workshop} />
        <CommonRoutes />
        {accessType === 'agent' && <QuotationRoutes />}
        <Suspense fallback={<Fallback />}>
          {accessType === 'gas_station' && <GasStationRoutes />}
          {accessType === 'fleet' && <FleetRoutes />}
          {accessType === 'workshop' && <WorkshopRoutes />}
        </Suspense>
      </>
    );
  }, [accessType, workshop]);

  if (accessType === null) {
    return <Fallback />;
  }

  return <NotificationProvider>{handleAccessType()}</NotificationProvider>;
};
