import React, { useState, useRef } from 'react';
import { PlusCircle } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderComponent } from '../../../shared/HeaderComponent';
import styles from './order-service-list.module.css';
import { SearchBar } from './components/SearchBar';
import { removeObjectFromLocal } from '../../../utils/localStorage';
import { DescriptionTable } from './components/DescriptionTable';
import { DeleteModal } from './components/DeleteModal';
import { Repair } from '../../common/History/interfaces';
import { Button } from '../../../shared/Button';
import { ListTable } from '../../../shared/ListTable';
import { RepairItem } from './components/RepairItem';
import { RepairActions } from './components/RepairAction';
import { useRepairData } from './useRepairData';
import { useRepairActions } from './useRepairActions';
import { FinishModal } from './components/FinishModal';

export const OrderServiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    filteredRepairs,
    isLoading,
    loadMoreData,
    page,
    repairs,
    setRepairs,
    searchQuery,
    setSearchQuery,
    setFilteredRepairs,
  } = useRepairData();

  const {
    handleDeleteOS,
    handleHistory,
    handleSelectRepair,
    handleUpdateOS,
    handleViewOS,
  } = useRepairActions({
    dispatch,
    navigate,
  });

  const actionsRef = useRef<HTMLDivElement | null>(null);
  const [selectedRepair, setSelectedRepair] = useState<null | Repair>(null);
  const [selectedRepairToRemove, setSelectedRepairToRemove] =
    useState<null | Repair>(null);
  const [selectedRepairToFinish, setSelectedRepairToFinish] =
    useState<null | Repair>(null);

  const onFinish = (updatedRepair: Repair) => {
    setRepairs(previousRepairs => {
      const updatedRepairs = previousRepairs.map(previousRepair => {
        if (previousRepair.id_repair === updatedRepair.id_repair) {
          return updatedRepair;
        }
        return previousRepair;
      });

      return updatedRepairs.sort((a, b) => {
        if (a.finished && b.finished) return 0;
        if (a.finished && !b.finished) return 1;
        if (!a.finished && b.finished) return -1;
        return 0;
      });
    });
  };

  return (
    <div className={styles.container}>
      <HeaderComponent title="Ordem de Serviço" />
      <DeleteModal
        onDelete={() => {
          if (selectedRepairToRemove) {
            if (searchQuery.length !== 7) {
              setRepairs(current =>
                current.filter(
                  repair =>
                    repair.id_repair !== selectedRepairToRemove.id_repair,
                ),
              );
            } else {
              setFilteredRepairs(current =>
                current.filter(
                  repair =>
                    repair.id_repair !== selectedRepairToRemove.id_repair,
                ),
              );
            }
          }
        }}
        onClose={() => {
          setSelectedRepairToRemove(null);
        }}
        repair={selectedRepairToRemove}
      />

      <FinishModal
        onFinish={(updatedRepair: Repair) => {
          onFinish(updatedRepair);
        }}
        onClose={() => {
          setSelectedRepairToFinish(null);
        }}
        repair={selectedRepairToFinish}
      />

      <section>
        <div className={styles['order-service-list']}>
          <div className={styles['order-service-list-header']}>
            <div className={styles.actions} ref={actionsRef}>
              <SearchBar
                licensePlate={searchQuery}
                setLicensePlate={setSearchQuery}
              />
              <div className={styles.info}>
                <div className={styles.desktop}>
                  <DescriptionTable />
                </div>
                <div className={styles.button}>
                  <Button
                    handleClick={() => {
                      removeObjectFromLocal('@local-order-service');
                      navigate(`/create-order-service`);
                    }}
                    style={{
                      fontWeight: 700,
                      padding: '1rem',
                      boxShadow: '0px 2px 2px #00000044',
                      fontSize: '1.2rem',
                    }}
                  >
                    <PlusCircle size="2rem" /> Nova O.S.
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.mobile}>
              <DescriptionTable />
            </div>
          </div>
          <ListTable.Root
            message={
              !isLoading &&
              repairs.length === 0 &&
              searchQuery.trim().length === 0
                ? 'Nenhuma OS encontrada'
                : `Nenhum OS encontrada para a placa ${searchQuery}`
            }
            isEmpty={
              !isLoading &&
              repairs.length === 0 &&
              searchQuery.trim().length === 0
            }
            notFound={
              !isLoading &&
              filteredRepairs.length === 0 &&
              searchQuery.trim().length > 0
            }
          >
            <ListTable.Head>
              <span className={styles['mobile-header']}>Código</span>
              <span> Cliente</span>
              <span>Placa</span>
              <span className={styles['mobile-header']}>Veículo</span>
              <span className={styles['mobile-header']}>Contato</span>
              <span>Data</span>
            </ListTable.Head>
            <ListTable.Body
              isLoading={isLoading}
              onScroll={() => loadMoreData(page)}
              style={{
                paddingBottom: '2rem',
              }}
            >
              {(filteredRepairs.length > 0 || repairs.length > 0) &&
                (searchQuery.trim().length > 0 ? filteredRepairs : repairs).map(
                  repair => (
                    <React.Fragment key={repair.id_repair}>
                      <ListTable.Row
                        handleClick={() =>
                          handleSelectRepair(repair, setSelectedRepair)
                        }
                        selected={
                          !!selectedRepair &&
                          selectedRepair.id_repair === repair.id_repair
                        }
                      >
                        <RepairItem repair={repair} />
                      </ListTable.Row>
                      {!!selectedRepair &&
                        selectedRepair.id_repair === repair.id_repair && (
                          <ListTable.SubRow>
                            <RepairActions
                              handleDeleteOS={() =>
                                handleDeleteOS(
                                  repair,
                                  setSelectedRepairToRemove,
                                )
                              }
                              handleHistory={
                                repair?.vehicle_id
                                  ? () => handleHistory(repair)
                                  : undefined
                              }
                              handleUpdateOS={() => handleUpdateOS(repair)}
                              handleViewOS={() =>
                                handleViewOS(repair, searchQuery)
                              }
                              handleFinishOS={
                                !repair?.finished
                                  ? () => setSelectedRepairToFinish(repair)
                                  : undefined
                              }
                            />
                          </ListTable.SubRow>
                        )}
                    </React.Fragment>
                  ),
                )}
            </ListTable.Body>
          </ListTable.Root>
        </div>
      </section>
    </div>
  );
};
